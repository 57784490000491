import { Component } from '@angular/core';

@Component({
  selector: 'app-global-withdrawals',
  templateUrl: './global-withdrawals.component.html',
  styleUrls: ['./global-withdrawals.component.scss']
})
export class GlobalWithdrawalsComponent {

}
