import { Component } from '@angular/core';

@Component({
  selector: 'app-proximamente',
  templateUrl: './proximamente.component.html',
  styleUrls: ['./proximamente.component.scss']
})
export class ProximamenteComponent {

}
